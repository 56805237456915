<template>
  <div class="master-toko">
    <header-page>
      <span
        class="size14 fw-bold-700"
        style="color: #0B5FFF;margin-right: 5px;"
      >Master</span>
      <span
        class="text-dark text-darken-5"
        style="margin-right: 5px;"
      >></span>
      <span class="text-dark fw-bold-400 size14">Toko</span>
    </header-page>

    <b-container
      fluid
      v-if="loadingProfile == false && loadingMerchant == false"
      class="mt-2"
    >
      <b-row>
        <Profile />
      </b-row>
      <!-- <Subscribe 
        :get-data="getDataMerchant" 
        :merchant-data="merchantData"
      /> -->
      <!-- <BankAccounts /> -->
    </b-container>
  </div>
</template>

<script>
import {
  BContainer, VBToggle, BRow,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'

import HeaderPage from '@/components/HeaderPage.vue'
import Profile from '@/components/Master/Toko/Profile.vue'
import BankAccounts from '@/components/Master/Toko/BankAccounts.vue'
import Subscribe from '@/components/Master/Toko/Subscribe.vue'

export default {
  title() {
    return 'Master Toko'
  },
  components: {
    HeaderPage,
    BRow,
    BContainer,
    Profile,
    BankAccounts,
    Subscribe,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      loadingProfile: true,
      loadingMerchant: true,
      merchantData: {},
    }
  },
  computed: {
  },
  created() {
    this.getDataProfile()
    this.getDataMerchant()
  },
  methods: {
    ...mapActions('profile', ['getMerchant']),
    ...mapActions('profile', ['getProfile']),
    async getDataMerchant() {
      this.loadingMerchant = true
      await this.getMerchant()
        .then(result => {
          this.$store.commit('profile/setMerchant', result.data.data)
          this.merchantData = result.data.data;
          this.loadingMerchant = false
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async getDataProfile() {
      this.loadingProfile = true
      await this.getProfile()
        .then(result => {
          this.loadingProfile = false
          this.$store.commit('profile/setProfile', result.data.data)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.master-toko {
  button.bg-white {
    &:focus {
      background-color: transparent !important;
    }
  }
}
</style>
