<template>
  <b-modal
    id="modal-profile"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <ModalHeader
        :left-text="'Kembali'"
        :middle-text="'Toko Profile'"
        :left-function="directPage"
        :is-rigt-column="true"
        :right-function="UpdateItem"
        :right-text="'Simpan'"
      />
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="8"
          >
            <!-- <b-row>
              <b-col
                cols="12"
                class="text-center mb-3"
              >
                <UploadPhoto
                  v-if="photo_url == ''"
                  :label-for="'upload_photo'"
                  :style-name="'width:140px; height:140px; margin: 0 auto; background-size:contain;'"
                  :photo-url-parent="'asdasd'"
                  @photo="getPhoto"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_url});width: 140px;height: 140px; margin: 0 auto; background-size: contain;`"
                    @click="photo_url = ''"
                  >
                    <b-avatar
                      class="floating__close--button"
                    >
                      <feather-icon
                        class="text-danger"
                        icon="XIcon"
                      />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
            </b-row> -->
            <b-row align-h="center">
              <!-- <b-col
                cols="6"
                class="border-right"
              > -->
              <b-col
                cols="8"
              >
                <!-- Nama Toko -->
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-nama-toko">Nama Toko <span class="text-danger">*</span>
                  </label>
                  <b-form-group>
                    <b-form-input
                      id="v-nama-toko"
                      v-model="formPayload.name"
                      type="text"
                      placeholder="Nama Toko"
                      :class="Array.isArray(messages.name) ? 'error-validation' : ''"
                      class="custom__input"
                      :disabled="profile && profile.merchant && profile.merchant.name_update_count > 0"
                    />
                    <small class="text-danger">
                      Nama Toko hanya dapat diubah satu kali.
                    </small>
                    <small
                      v-if="messages.name && Array.isArray(messages.name) && messages.name.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.name.join(', ') }}</small>
                  </b-form-group>
                </b-col>

                <!-- Ecommerce Username -->
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-nama-toko">Ecommerce Username <span class="text-danger">*</span>
                  </label>
                  <b-form-group>
                    <b-form-input
                      id="v-nama-toko"
                      v-model="formPayload.ecommerce_username"
                      type="text"
                      placeholder="Ecommerce Username"
                      :class="Array.isArray(messages.name) ? 'error-validation' : ''"
                      class="custom__input"
                      :disabled="profile && profile.merchant && profile.merchant.ecommerce_username_update_count > 0"
                    />
                    <small class="text-danger">
                      Ecommerce Username hanya dapat diubah satu kali.
                    </small>
                    <small
                      v-if="messages.name && Array.isArray(messages.name) && messages.name.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.name.join(', ') }}</small>
                  </b-form-group>
                </b-col>

                <!-- Alamat Toko
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-alamat">Alamat Toko <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-textarea
                      id="v-alamat"
                      v-model="formPayload.address"
                      rows="3"
                      placeholder="Alamat Toko"
                      :class="Array.isArray(messages.address) ? 'error-validation' : ''"
                      class="custom__textarea"
                    />
                    <small
                      v-if="messages.address && Array.isArray(messages.address) && messages.address.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.address.join(', ') }}</small>
                  </b-form-group>
                </b-col>
                Nama di Struk
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-struk">Nama di Struk <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-input
                      id="v-struk"
                      v-model="formPayload.name_in_invoice"
                      type="text"
                      placeholder="Nama di Struk"
                      :class="Array.isArray(messages.name_in_invoice) ? 'error-validation' : ''"
                      class="custom__input"
                    />
                    <small
                      v-if="messages.name_in_invoice && Array.isArray(messages.name_in_invoice) && messages.name_in_invoice.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.name_in_invoice.join(', ') }}</small>
                  </b-form-group>
                </b-col>
                Nama Alias
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-nama-alias">Nama Alias <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-input
                      id="v-nama-alias"
                      v-model="formPayload.alias_name"
                      type="text"
                      placeholder="Nama Alias"
                      :class="Array.isArray(messages.alias_name) ? 'error-validation' : ''"
                      class="custom__input"
                    />
                    <small
                      v-if="messages.alias_name && Array.isArray(messages.alias_name) && messages.alias_name.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.alias_name.join(', ') }}</small>
                  </b-form-group>
                </b-col>
                Kota
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-kota">Kota <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-input
                      id="v-kota"
                      v-model="formPayload.city"
                      type="text"
                      placeholder="Kota"
                      :class="Array.isArray(messages.city) ? 'error-validation' : ''"
                      class="custom__input"
                    />
                    <small
                      v-if="messages.city && Array.isArray(messages.city) && messages.city.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.city.join(', ') }}</small>
                  </b-form-group>
                </b-col> -->
              </b-col>
              <!-- <b-col cols="6">
                Kode Pos
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2 custom__form--input"
                >
                  <label for="v-postal-code">Kode Pos <span class="text-danger">*</span></label>
                  <b-form-group>
                    <b-form-input
                      id="v-postal-code"
                      v-model="formPayload.postal_code"
                      type="number"
                      placeholder="Kode Pos"
                      :class="Array.isArray(messages.postal_code) ? 'error-validation' : ''"
                      class="custom__input"
                    />
                    <small
                      v-if="messages.postal_code && Array.isArray(messages.postal_code) && messages.postal_code.length > 0"
                      class="text-error fw-bold-500 size12"
                    >{{ messages.postal_code.join(', ') }}</small>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  class="custom__form--input"
                >
                  <label for="">Pin Lokasi di Peta</label>
                  <GeolocationSelectorMap
                    :key="1"
                    v-model="selectedLocation"
                    :default-location="currentLocation"
                  />
                  <Maps
                    :longitude="merchant.address_latitude"
                    :latitude="merchant.address_longitude"
                    :zoom-map="18"
                  />
                </b-col>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BAvatar,
} from 'bootstrap-vue'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import UploadPhoto from '@/components/UploadPhoto.vue'
import Maps from '@/components/Maps.vue'
import GeolocationSelectorMap from '@/components/GeolocationSelectorMap.vue'
import ModalHeader from '@/components/ModalHeader.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    Maps,
    GeolocationSelectorMap,
    ModalHeader,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    getData: {
      type: Function,
    },
    formData: {
      type: Object,
    },
    photoUrl: {
      type: String,
    },
    getProfile: {
      type: Function,
    },
    profile: Object,
  },
  watch: {
    formData: {
      handler(value) {
        // this.currentLocation = {
        //   lat: value.address_latitude || this.currentLocation.lat || '',
        //   lng: value.address_longitude || this.currentLocation.lng || '',
        // }
        this.messages = ''
        this.formPayload = value
      },
      deep: true,
    },
    photoUrl(value) {
      this.photo_url = value
    },
  },
  data() {
    return {
      merchant: this.$store.state.profile.merchant,
      photo_url: '',
      selectedLocation: {},
      currentLocation: {
        lat: this.$store.state.profile.merchant.address_latitude,
        lng: this.$store.state.profile.merchant.address_longitude,
      },
      formPayload: {
        name: '',
        ecommerce_username: '',
        // jabatan: '',
        // address: '',
        // name_in_invoice: '',
        // alias_name: '',
        // alias_address: '',
        // city: '',
        // postal_code: '',
        // address_latitude: '',
        // address_longitude: '',
      },
      messages: '',
    }
  },
  mounted() {
    this.getUserPosition()
  },
  methods: {
    ...mapActions('profile', ['postMerchant']),
    preparePayload() {
      const form = new FormData()

      // if (this.profile && this.profile.merchant && this.profile.merchant.ecommerce_username_update_count > 0) {
      //   delete this.formPayload.ecommerce_username;
      // } else if(this.profile && this.profile.merchant && this.profile.merchant.name_update_count > 0){
      //   delete this.formPayload.name
      // }
      
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    async UpdateItem() {
      // this.formPayload.address_latitude = this.selectedLocation.position.lat
      // this.formPayload.address_longitude = this.selectedLocation.position.lng

      const form = this.preparePayload()

      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin mengubah data Profil ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // User clicked the Confirm button
          this.postMerchant(form)
            .then((result) => {
              this.photo_url = result.data.data.logo
              successNotification(this, 'Success', 'Profile berhasil di perbaharui')
              this.getData()
              this.directPage()
              this.getProfile()
              // this.getUserPosition()
            })
            .catch((err) => {
              if (err.response.data.meta.messages) {
                this.messages = err.response.data.meta.messages
                // errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      this.$bvModal.hide('modal-profile')
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.currentLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }
          this.selectedLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 151px;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
